//存储用户登录信息
export default {
    namespaced: true,
    state: {
        userinfo: {
            account: '',
            accessToken: '',
            menu: ''
        }
    },
    mutations: {
        //登录信息设置
        setUser(state,paylod){
            state.userinfo = paylod
        },
        //退出清空用户信息
        removeUser(state,paylod){
            state.userinfo = {
                account: '',
                accessToken: '',
                menu: ''
            }
        }
    }

}