import { Message } from "element-ui";
import store from '@/store';
import {onUpdated} from "vue";
// import { $router } from "element-ui";

// 配置API接口地址
//var root = 'http://127.0.0.1:8081'
var root = 'http://admin.bihucar.cn'

// 引用axios
var axios = require('axios')

// 自定义判断元素类型JS
function toType (obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

// 参数过滤函数
function filterNull (o) {
    for (var key in o) {
        if (o[key] === null) {
            delete o[key]
        }
        if (toType(o[key]) === 'string') {
            o[key] = o[key].trim()
        } else if (toType(o[key]) === 'object') {
            o[key] = filterNull(o[key])
        } else if (toType(o[key]) === 'array') {
            o[key] = filterNull(o[key])
        }
    }
    return o
}

//设置文本请求方式
function apiAxios_request_application(){
    // request 拦截器
    // 可以自请求发送前对请求做一些处理
    // 比如统一加token，对请求参数统一加密
    axios.interceptors.request.use(config => {
        config.headers['Content-Type'] = ['application/x-www-form-urlencoded;charset=UTF-8','multipart/form-data'];
        //config.headers['Content-Type'] = 'application/json'
        if(store.state.login.userinfo.accessToken){
            config.headers['access_token'] = store.state.login.userinfo.accessToken;
        }
        return config
    }, error => {
        return Promise.reject(error)
    });
}


//接口处理函数
function apiAxios (method, url, params, success, failure) {
    if (params) {
        params = filterNull(params)
    }
    apiAxios_request_application();
    axios({
        method: method,
        url: url,
        data: method === 'POST' || method === 'PUT' ? params : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        baseURL: root,
        withCredentials: false
    })
        .then(function (res) {
            if (res.data.code === '200') {    //接口调用成功
                if (success) {
                    success(res.data)
                }
            } else {    //失败
                if(res.data.code === '101'){
                    store.commit('RESET_STATE');
                    localStorage.removeItem("info");
                    Message.error(res.data.message);
                    location.href = '/login'
                }else{
                    // Message.error(res.data.message);
                    success(res.data)
                }
            }
        })
        .catch(function (err) {
            let res = err.response
            if (res) {
                var datacode = {}
                switch (res.status) {
                    case 400:
                        datacode = {"code":"400","message":"请求错误"};
                        // Message.error("请求错误");
                        break
                    case 403:
                        datacode = {"code":"403","message":"拒绝访问"};
                        // Message.error("拒绝访问");
                        break
                    case 404:
                        datacode = {"code":"404","message":"请求地址出错"};
                        // Message.error("请求地址出错");
                        break
                    case 408:
                        datacode = {"code":"408","message":"请求超时"};
                        // Message.error("请求超时");
                        break
                    case 500:
                        datacode = {"code":"500","message":"服务器内部错误"};
                        // Message.error("服务器内部错误");
                        break
                    case 501:
                        datacode = {"code":"501","message":"服务未实现"};
                        // Message.error("服务未实现");
                        break
                    case 502:
                        datacode = {"code":"502","message":"网关错误"};
                        // Message.error("网关错误");
                        break
                    case 503:
                        datacode = {"code":"503","message":"服务不可用"};
                        // Message.error("服务不可用");
                        break
                    case 504:
                        datacode = {"code":"504","message":"网关超时"};
                        // Message.error("网关超时");
                        break
                    case 505:
                        datacode = {"code":"505","message":"HTTP版本不受支持"};
                        // Message.error("HTTP版本不受支持");
                        break
                    default:
                        datacode = {"code":"-1","message":"系统异常"}
                        // Message.error("系统异常");
                        break
                }
                success(datacode)
            }
        })
}


// 返回在vue模板中的调用接口
export default {
    get: function (url, params, success, failure) {
        return apiAxios('GET', url, params, success, failure)
    },
    post: function (url, params, success, failure) {
        return apiAxios('POST', url, params, success, failure)
    },
    put: function (url, params, success, failure) {
        return apiAxios('PUT', url, params, success, failure)
    },
    delete: function (url, params, success, failure) {
        return apiAxios('DELETE', url, params, success, failure)
    }
}