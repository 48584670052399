import Vue from 'vue'
import VueRouter from 'vue-router'
import CommonData from '@/utils/commonData'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: '登录', component: () => import('../views/Login.vue') },
  { path: '/403', name: '权限不足', component: () => import('../views/403.vue') },
  { path: '/', redirect: "/home" }, // 将/重定向到/home
  {
    path: '/home',
    name: 'home',
    meta: {title: '首页', authRequired: true},
    redirect: "/index",
    component: () => import('../views/Manage.vue'),
    children: [
      // { path: '/index', name: 'index', meta: {title: '首页'}, component: () => import('../views/index/index.vue')},
    
      // { path: '/user', name: 'role', meta: {title: '用户管理'}, component: () => import('../views/index/user/page/index.vue'),
      //   children: [
      //     { path: '/user/page', name: 'page', meta: {title: '用户列表',showFather: false}, component: () => import('../views/index/user/page/index.vue')},
      //     { path: '/user/role', name: 'test1', meta: {title: '角色管理'}, component: () => import('../views/index/user/role/index.vue'),
      //       children: [
      //         { path: '/user/role1/page', name: 'page', meta: {title: '测试',showFather: false}, component: () => import('../views/index/user/role/index.vue')},
      //       ]
      //     }
      //   ]
      // }
    ]
  },

]

//动态路由
const homeRoutes = routes.filter(v=>v.path=='/home')[0];
homeRoutes.children = [];

initRoutes(CommonData,homeRoutes.children)

function initRoutes(data,subObj){
  data.forEach(item=>{
    if(item.child != undefined && item.child instanceof Array){
      initRoutes(item.child,subObj);
    }else{
      let obj = {
            path: item.url,
            name: item.name,
            meta:{title: item.title},
            component: () => import('../views/'+item.component+'/index.vue')
          };
      subObj.push(obj);
    }
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 设置路由全局前置守卫导航
router.beforeEach((to, from, next) => {
  //判断进入路由是否需要登录
  if(to.matched.some(ele => ele.meta.authRequired)){  //需要登录
    //判断是否已经登录 token值是否存在
    //需要验证是否有权限
    //权限验证思路：通过登录后存储的权限，跟用户点击菜单比较，有则true，无则false

    //console.log(to.path);

    //console.log(store.state.login.userinfo.menu);

    const isPermission = authorization(to.path, store.state.login.userinfo.menu);

    if(store.state.login.userinfo.accessToken){
      if(!isPermission){
        next('/403');
      }else{
        next();
      }
    }else{
      next('/login');
    }
  }else{  //不需要登录
    next();
  }
});

//验证是否有权限 通过点击菜单按钮和用户当前权限验证
function authorization(clickMenu, menuList){
  let status = false;
  if(menuList != null && menuList.length > 0){
    menuList.forEach((item) => {
      if(clickMenu.includes(item.menuPath)){
        status = true;
      }
    });
  }
  return status;
}


export default router
