export default[
    {name: '首页', url: '/index', title: '首页', component: 'index'},
    {name: '用户管理', url: '', title: '用户管理', component: 'user', child: [
        {name: '用户列表', url: '/user/user', title: '用户列表', component: 'user/user'},
        {name: '系统管理员', url: '/user/admin_sys', title: '系统管理员', component: 'user/admin_sys'},
        {name: '公司管理员', url: '/user/admin_com', title: '公司管理员', component: 'user/admin_com'},
        {name: '登录日志', url: '/user/loginlog', title: '登录日志', component: 'user/loginlog'},
        {name: '公司管理', url: '/user/company', title: '公司管理', component: 'user/company'}
    ]},
    {name: '钱包管理', url: '', title: '钱包管理', component: 'wallet', child:[
            {name: '用户余额', url: '/wallet/balance', title: '用户余额', component: 'wallet/balance'},
            {name: '用户账单', url: '/wallet/bill', title: '用户账单', component: 'wallet/bill'},
            {name: '提现申请', url: '/wallet/withdrawal', title: '提现申请', component: 'wallet/withdrawal'}
        ]},
    {name: '商城管理', url: '', title: '商城管理', component: 'shop', child:[
            {name: '商品管理', url: '/shop/goods', title: '商品管理', component: 'shop/goods', child: [
                    {name: '新增商品', url: '/shop/goods/add', title: '新增商品', component: 'shop/goods/add'},
                    {name: '修改商品', url: '/shop/goods/update', title: '修改商品', component: 'shop/goods/update'}
                ]},
            {name: '商品分类', url: '/shop/category', title: '商品分类', component: 'shop/category'},
            {name: '商品规格', url: '/shop/specs', title: '商品规格', component: 'shop/specs'}
        ]},

    {name: '二手车市场', url: '', title: '二手车市场', component: 'secondhand', child:[
        {name: '二手车管理', url: '/secondhand/vehicle', title: '二手车管理', component: 'secondhand/vehicle'},
        {name: '门市管理', url: '/secondhand/market', title: '二手车管理', component: 'secondhand/market'},
        {name: '热门品牌', url: '/secondhand/hotBrand', title: '热门品牌', component: 'secondhand/hotBrand'}
    ]},
    {name: '挪车管理', url: '', title: '挪车管理', component: 'nuoche', child:[
            {name: '挪车记录', url: '/nuoche/vehicle', title: '挪车记录', component: 'nuoche/vehicle'},
            {name: '车辆绑定', url: '/nuoche/record', title: '车辆绑定', component: 'nuoche/record'}
        ]},
    {name: '审核管理', url: '', title: '审核管理', component: 'examine', child:[
            {name: '二手车审核', url: '/examine/vehicle', title: '二手车审核', component: 'examine/vehicle'},
        ]},
    {name: '车辆管理', url: '', title: '车辆管理', component: 'car', child:[
        {name: '品牌管理', url: '/car/brand', title: '品牌管理', component: 'car/brand'},
        {name: '厂商管理', url: '/car/manufacturer', title: '厂商管理', component: 'car/manufacturer'},
        {name: '型号管理', url: '/car/model', title: '型号管理', component: 'car/model'},
        {name: '版本管理', url: '/car/version', title: '版本管理', component: 'car/version'}
    ]},
    {name: '系统管理', url: '', title: '系统管理', component: 'sys', child: [
        {name: '角色管理', url: '/sys/role', title: '角色管理', component: 'sys/role'},
        {name: '菜单管理', url: '/sys/menu', title: '菜单管理', component: 'sys/menu'},
        {name: '图标管理', url: '/sys/icon', title: '图标管理', component: 'sys/icon'},
        {name: '用户协议', url: '/sys/agreement', title: '用户协议', component: 'sys/agreement'},
        {name: '系统版本管理', url: '/sys/versionConfig', title: '系统版本管理', component: 'sys/versionConfig'},
        {name: '轮播图管理', url: '/sys/banner', title: '轮播图管理', component: 'sys/banner'},
        {name: '意见反馈', url: '/sys/feedback', title: '意见反馈', component: 'sys/feedback'},
        {name: '地址管理', url: '/sys/address', title: '地址管理', component: 'sys/address'}
    ]},
    {name: 'ETC管理', url: '', title: 'ETC管理', component: 'ETC', child:[
        {name: 'ETC自营服务管理', url: '/ETC/outlet', title: 'ETC自营服务管理', component: 'ETC/outlet'}
    ]},
    {name: '租车管理', url: '', title: '租车管理', component: 'hire', child:[
        {name: '租车商铺', url: '/hire/car', title: '租车商铺', component: 'hire/car'}
    ]},
    {name: '加油站管理', url: '', title: '加油站管理', component: 'refuel', child:[
        {name: '加油站', url: '/refuel/station', title: '加油站', component: 'refuel/station'},
        {name: '油价管理', url: '/refuel/oilPrice', title: '油价管理', component: 'refuel/oilPrice'}
    ]},
    {name: '保险管理', url: '', title: '保险管理', component: 'insure', child:[
            {name: '保险设置', url: '/insure/business', title: '保险设置', component: 'insure/business'}
        ]}
]