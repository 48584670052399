import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate  from 'vuex-persistedstate'
import login from './modules/login'

Vue.use(Vuex)

export default new Vuex.Store({
// const store = new Vuex.Store({
  state: {
    tabsList: [{menuName: '首页',menuPath: '/index',routeName: '首页'}],    //多页签导航的数据
  },
  getters: {    //vuex计算属性
    getTabsList: state => state.tabsList
  },
  mutations: {  //修改state状态
    ADD_TAGS(state,data){   //数据添加
        state.tabsList.push(data)
    },
    DEL_TAGS(state,i){   //数据删除
      state.tabsList.splice(i,1)
    },
    RESET_STATE: (state) => {
      state.tabsList = [{menuName: '首页',menuPath: '/index',routeName: '首页'}]
    },
  },
  actions: {    //管理或提交mutatuins
    ADD_TAGS({commit},data){
        commit('ADD_TAGS',data)
    },
    DEL_TAGS({commit},i){
      commit('DEL_TAGS',i)
    },
  },
  modules: {
    login
  },
  plugins: [
    createPersistedstate({
      storage: window.localStorage, //存储方式：localStorage、sessionStorage、cookies
      key: 'info',  // 存储的的key值
      // paths: ['info'],
      reducer(v){
        return {
          ...v
        }
      }
    })
  ]
})
// export default store


